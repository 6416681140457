<template>    
    <vx-card title="SKU View">
        <div class="vx-input-group flex">
            <vs-tabs :value="activeTab">
                <vs-tab label="Data">
                    <tab-data></tab-data>
                </vs-tab>
                <vs-tab label="Export">
                    <tab-export></tab-export>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabData from "./TabData.vue";
import TabExport from "./TabExport.vue";
export default {
    components: {
        TabData,
        TabExport,
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted(){
        localStorage.removeItem('master-sku-view-data')
    }
}
</script>